<template>
  <div class="grey lighten-2 fill-height">
    <div
      align="center"
      style="
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <v-card align="start" width="500" elevation="5">
        <v-card-title class="text-h5 font-weight-medium">
          Recuperação de Senha
        </v-card-title>
        <v-card-text>
          <v-form ref="passwordRecoveryForm" @submit.prevent="changePassword()">
            <v-text-field
              v-model="recovery.password"
              label="Nova senha *"
              filled
              class="white mb-2"
              hide-details="auto"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[$rules.required, $rules.passwordMin]"
              :disabled="loading"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
            />
            <v-text-field
              label="Confirmação da nova senha *"
              filled
              class="white mb-2"
              hide-details="auto"
              :disabled="loading"
              :append-icon="showRepeat ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showRepeat = !showRepeat"
              :type="showRepeat ? 'text' : 'password'"
              :rules="[$rules.required, $rules.equalPassword(() => recovery.password)]"
            />
            <div class="d-flex mt-4">
              <v-spacer />
              <v-btn
                color="green"
                dark
                :loading="loading"
                type="submit"
              >
                Definir nova senha
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "RecoverPassword",

  data: () => ({
    loading: false,
    showPassword: false,
    showRepeat: false,
    confirmPassword: false,
    recovery: {
      password: "",
    },
  }),
  methods: {
    changePassword() {
      if (!this.$refs.passwordRecoveryForm.validate()){
        this.$showMessage("warning", "Preencha os campos corretamente");
      } else {
        this.loading = true;
        this.$axios
          .put('/recover-password', {
            "code": this.$route.params.code,
            "new_password": this.recovery.password,
          })
          .then(() => {
            this.$showMessage("success", "Senha alterada com sucesso");
            this.$router.replace({ name: 'login' });
          })
          .catch((error) => {
            if (error.response){
              switch (error.response.status){
                case 404: this.$showMessage("error", "Solicitação de recuperação de senha não encontrado"); return;
                case 408: this.$showMessage("error", "Este link para recuperação da senha expirou"); return;
                case 409: this.$showMessage("error", "A nova senha é igual a sua senha antiga"); return;
                case 410: this.$showMessage("error", "Este link para recuperação de senha já foi utilizado"); return;
              }
            }
            this.$showMessage("error", "Falha na alteração de senha");
          })
          .finally(() => {
            this.loading = false;
          })
      }
    }
  }
};
</script>
